<template>
  <div id="app" :class="{ 'mobile-layout': isMobile }">
    <nav :class="{ 'side-nav': !isMobile, 'top-nav': isMobile }">
      <div
        v-for="(link, index) in links"
        :key="index"
        :class="{ 'active': currentSection === link.to }"
        @click="scrollToSection(link.to)"
      >
        {{ link.text }}
      </div>
      <div class="tab-link dropdown" :class="{ 'open': isDropdownOpen }">
        <a @click.stop="toggleDropdown">
          More
          <svg class="dropdown-icon" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 9L12 15L18 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <div v-if="isDropdownOpen" class="dropdown-content">
          <a href="https://wukuizong.notion.site/0426873c276e4e9f9b723aa1cf217444?v=10ea67d3d4e64ac4898a388c09fe7c71&pvs=25" target="_blank" @click.stop>
            RoadMap
            <svg class="external-link-icon" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </a>
          <a href="https://app.youform.com/forms/dsq4erex" target="_blank" @click.stop>
            Bug Report
            <svg class="external-link-icon" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </a>
        </div>
        <SpeedInsights />
      </div>
    </nav>

    <main ref="mainContent">
      <div class="content-container">
        <section v-for="link in links" :key="link.to" :id="link.to.slice(1)">
          <component :is="link.component"></component>
        </section>
      </div>
      <ScrollIndicator :mainContent="mainContent" />
    </main>

    <div v-if="isMobile" class="chat-bubble" @click="toggleChat">
      <div class="icon-container">
        <transition name="icon-switch" mode="out-in">
          <img v-if="!isChatOpen" key="chat" src="@/assets/icons/chatbot.svg" alt="Chat Icon" class="chat-icon"/>
          <span v-else key="close" class="close-icon">&times;</span>
        </transition>
      </div>
    </div>

    <transition name="chat-animation">
      <div 
        v-if="isMobile && isChatOpen" 
        class="mobile-chat"
      >
        <MicrophoneButton @sectionChange="handleSectionChange" />
      </div>
    </transition>

    <div v-if="!isMobile" class="avatar-container">
      <MicrophoneButton @sectionChange="handleSectionChange" />
    </div>

  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, defineAsyncComponent, watch } from 'vue';
import MicrophoneButton from './components/MicrophoneButton.vue';
import ScrollIndicator from './components/ScrollIndicator.vue';
import { useRouter } from 'vue-router';
import { useHead } from '@vueuse/head';
import { SpeedInsights } from "@vercel/speed-insights/vue";


export default {
  name: 'App',
  components: {
    MicrophoneButton,
    ScrollIndicator,
    SpeedInsights,
  },
  setup() {
    const router = useRouter();
    const mainContent = ref(null);
    const currentSection = ref('/digital-twin');
    const isMobile = ref(false);
    const isChatOpen = ref(false);
    const isDropdownOpen = ref(false);

    const links = [
      { to: '/digital-twin', text: 'Digital Twin', component: defineAsyncComponent(() => import('./components/DigitalTwin.vue')) },
      { to: '/about-me', text: 'About Me', component: defineAsyncComponent(() => import('./components/AboutMe.vue')) },
      { to: '/contact', text: 'Contact', component: defineAsyncComponent(() => import('./components/ContactMe.vue')) },
    ];

    const checkMobile = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    const toggleChat = () => {
      isChatOpen.value = !isChatOpen.value;
    };

    const toggleDropdown = (event) => {
      event.stopPropagation();
      isDropdownOpen.value = !isDropdownOpen.value;
    };

    const closeDropdown = () => {
      isDropdownOpen.value = false;
    };

    const scrollToSection = (sectionId) => {
      const element = document.getElementById(sectionId.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      currentSection.value = sectionId;
      router.push(sectionId);
    };

    const updateActiveSection = () => {
      const scrollPosition = mainContent.value.scrollTop;
      const windowHeight = window.innerHeight;
      const offset = windowHeight / 3;

      for (const link of links) {
        const element = document.getElementById(link.to.slice(1));
        if (element) {
          const elementTop = element.offsetTop - offset;
          const elementBottom = elementTop + element.offsetHeight;

          if (scrollPosition >= elementTop && scrollPosition < elementBottom) {
            currentSection.value = link.to;
            break;
          }
        }
      }
    };

    const handleSectionChange = (section) => {
      console.log('Received section change:', section);
      if (typeof section === 'string' && section.startsWith('/')) {
        scrollToSection(section);
      } else {
        scrollToSection(`/${section}`);
      }
    };

    onMounted(() => {
      checkMobile();
      window.addEventListener('resize', checkMobile);
      document.addEventListener('click', closeDropdown);
      document.title = "Meet Vincent Wu";
      if (mainContent.value) {
        mainContent.value.addEventListener('scroll', updateActiveSection);
      }
      updateActiveSection(); // Initial check
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', checkMobile);
      document.removeEventListener('click', closeDropdown);
      if (mainContent.value) {
        mainContent.value.removeEventListener('scroll', updateActiveSection);
      }
    });

    watch(isMobile, () => {
      updateActiveSection();
    });

    useHead({
      title: "Meet Vincent Wu",
      meta: [
        { name: 'description', content: 'Welcome to my digital twin' },
        { property: 'og:title', content: 'Meet Vincent Wu' },
        { property: 'og:description', content: 'Welcome to my digital twin' },
        { property: 'og:url', content: 'https://vincent-wu.com' },
        { property: 'og:type', content: 'website' },
      ],
      link: [
        { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
      ],
    });


    return { 
      links, 
      mainContent, 
      currentSection, 
      scrollToSection, 
      isMobile, 
      isChatOpen, 
      toggleChat,
      handleSectionChange,
      isDropdownOpen,
      toggleDropdown,
    };
  },
};
</script>

<style>
@font-face {
  font-family: 'typewriter';
  src: url('@/assets/fonts/typewriter/typewriter-thin.otf') format('opentype');
}

body {
  margin: 0;
  padding: 0;
  background-color: hsl(32, 23%, 84%);
  color: #000000;
  font-family: typewriter;
  overflow: hidden;
}

html, body {
  height: 100%;
  overflow: hidden;
}

#app {
  display: flex;
  height: 100vh;
  position: relative;
}

.side-nav {
  margin-top: 5vh;
  width: 18vw;
  padding: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 0.1vh;
}

.top-nav {
  width: 100%;
  padding: 1vh 0;
  display: flex;
  justify-content: center;
  background-color: hsl(32, 23%, 84%);
  position: fixed;
  top: 0;
  z-index: 1000;
}

.side-nav div, .top-nav div {
  cursor: pointer;
  padding: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 1em; /* Add this line to set the base font size */
}

.side-nav div.active, .top-nav div.active {
  color: #0c42c8;
  font-weight: bold;
}

.tab-link a {
  text-decoration: none;
  color: black;
  display: inline-flex;
  align-items: center;
}

.external-link-icon {
  margin-left: 4px;
  transition: transform 0.2s ease-in-out;
}

.tab-link a:hover .external-link-icon {
  transform: translate(1px, -1px);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.9em !important; /* Add this line to make dropdown font smaller */
}

.dropdown-content a {
  color: #000000;
  text-decoration: none;
  padding: 1.5vh;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.dropdown-icon {
  transition: transform 0.3s ease;
  margin-left: 5px;
}

.dropdown.open .dropdown-icon {
  transform: rotate(180deg);
}

main {
  flex-grow: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.content-container {
  width: 50vw;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2vw;
}

section {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20vh;
}

.avatar-container {
  width: 15vw;
  display: flex;
  justify-content: center;
  padding: 2vw;
}

main::-webkit-scrollbar {
  display: none;
}

main {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mobile-layout {
  flex-direction: column;
}

.chat-bubble {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #0c42c8;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1001;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
}

.chat-bubble:hover {
  transform: scale(1.1);
}

.icon-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-icon, .close-icon {
  width: 24px;
  height: 24px;
  transition: all 0.1s ease-in-out;
}

.close-icon {
  color: white;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-switch-enter-active,
.icon-switch-leave-active {
  transition: all 0.3s ease-in-out;
}

.icon-switch-enter-from,
.icon-switch-leave-to {
  opacity: 0;
  transform: rotate(180deg) scale(0.5);
}

.icon-switch-enter-to,
.icon-switch-leave-from {
  opacity: 1;
  transform: rotate(0) scale(1);
}

.mobile-chat {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 300px;
  height: 400px;
  background-color: hsl(32, 23%, 84%);
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.chat-animation-enter-active,
.chat-animation-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.chat-animation-enter-from,
.chat-animation-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

@media (max-width: 768px) {
  html, body {
    overflow: auto;
  }

  #app {
    display: flex;
    flex-direction: column;
  }

  main {
    flex-grow: 1;
    overflow-y: auto;
    padding-top: 10vh;
  }

  .content-container {
    width: 90vw;
    padding: 2vw;
    margin: 0 auto;
  }

  section {
    margin-top: 2vh;
    min-height: auto;
  }

  .top-nav {
    height: 8vh;
    padding: 0;
  }

  .top-nav div {
    padding: 1vh;
    font-size: 0.9em;
  }

  .dropdown-content {
    font-size: 0.8em; /* Adjust this value for mobile if needed */
  }
}
</style>